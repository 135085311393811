<template>
    <section class="section has-margin">
      <div class="js-categories-overview teaser-categories" data-dialog-tabs="">
        <div class="categories-intro categories-text">
          <h2 class="h2 categories-text__section">Vacantes disponibles</h2>
          <div class="categories-text__section">Si eres de los que desafían sus límites y quieres unirte a un equipo enfocado y con objetivos, contáctanos. </div>

        </div>
        <div class="category-header category-header--rounded-corners is-active" data-analytics="productsOverviewTab" data-tab-header="390">
          <svg class="icon icon--land category-header__icon icon--svg" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 36 36" xml:space="preserve">
            <g>
                <path class="st0" d="M18,1c0.8,0,1.7,0.1,2.4,0.4c0.6,0.3,1.2,0.5,1.7,1c0.4,0.4,0.7,0.8,0.9,1.4c0.1,0.3,0.3,0.6,0.4,0.9
                  c0.3,0.9,0.2,1.9,0,2.8c0,0.1,0,0.2-0.1,0.2c-0.1,0.2-0.1,0.3,0,0.5c0.1,0.1,0.1,0.4,0,0.5c-0.1,0.6-0.3,1.2-0.6,1.8
                  c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.1-0.1,0.2c-0.3,0.8-0.7,1.5-1.3,2.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.3,0,0.5-0.1,0.7
                  c-0.5,1.1-1.3,2-2.3,2.6c-0.1,0-0.1,0.1-0.2,0.1c-0.4,0.2-0.7,0.2-1.1,0c-1.2-0.7-2-1.7-2.6-3c-0.1-0.1,0-0.3,0-0.5
                  c0-0.1,0-0.2-0.1-0.2c-0.5-0.7-1-1.4-1.3-2.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.3-0.4-0.4-0.7c-0.2-0.5-0.3-0.9-0.4-1.4
                  c-0.1-0.3-0.1-0.5,0.1-0.8c0,0,0-0.1,0-0.2c-0.3-1-0.4-1.9-0.2-2.9c0-0.2,0.1-0.4,0.2-0.6C12.9,4,13,3.5,13.2,3.2
                  c0.4-0.7,1-1.2,1.7-1.5c0.4-0.2,0.8-0.3,1.2-0.5C16.7,1.1,17.3,1,18,1z"/>
                <path class="st0" d="M15.4,19.7c0.2-0.3,0.5-0.7,0.7-1c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2c0.2,0.5,0.4,1,0.3,1.6
                  c0,0.2-0.1,0.4-0.2,0.4c-0.1,0-0.2,0-0.3,0c-0.5-0.1-1.1,0-1.6,0.1c-1.2,0.4-2,1.5-2.1,2.7c-0.8,0-1.7-0.1-2.4,0.4
                  c-0.7,0.5-1,1.5-1,2.4c-0.8-0.3-1.7-0.5-2.5-0.8c-0.3-0.1-0.7-0.2-0.9-0.5c-0.2-0.2-0.3-0.6-0.3-0.9C5.6,23,6,21.8,6.5,20.7
                  c0.4-0.9,0.7-1.8,1.4-2.5c1.7-1.7,4.6-1.6,6.2-3.3c0.3,1.7,0.6,3.5,0.9,5.2c0,0.3,0.3-0.1,0.3-0.1C15.3,19.9,15.4,19.8,15.4,19.7z"
                  />
                <path class="st0" d="M26.5,26.4c0.1-1-0.3-2.1-1.2-2.5c-0.6-0.3-1.4-0.3-2.1-0.3c0-1.4-1.1-2.6-2.5-2.9c-0.4-0.1-0.8-0.1-1.2,0
                  c-0.1,0-0.2,0-0.3,0c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.4,0-0.7,0.1-1.1c0.1-0.2,0.1-0.5,0.3-0.7c0.5-0.6,1.2,1.3,1.5,1.5
                  c0.1,0,0.2-0.7,0.2-0.8c0.2-1,0.3-2,0.5-3c0.1-0.3,0.1-0.6,0.2-0.9c0-0.2,0-0.2,0.1-0.7c0.7,0.9,1.8,1.3,2.8,1.6s2.1,0.7,3,1.4
                  c0.7,0.6,1.2,1.3,1.5,2.2c0.3,0.6,0.5,1.3,0.7,2c0.2,0.6,0.4,1.3,0.4,1.9c0,0.5-0.2,1-0.5,1.3c-0.2,0.2-0.5,0.3-0.8,0.4
                  C28.2,26,27.4,26.2,26.5,26.4z"/>
                <path class="st0" d="M21.5,25.3c0.2,0,0.5,0,0.7,0c0.7,0,1.3,0,2,0c0.4,0,0.7,0.3,0.7,0.7c0,0.1,0,0.1,0,0.2
                  c0.1,0.3-0.1,0.6-0.3,0.9c-0.5,0.7-1.1,1.2-1.9,1.6c-0.9,0.5-2.3,0.9-3.3,1c0-0.1,0-0.1,0-0.2c0-0.3-0.3-0.6-0.6-0.6
                  c-0.6,0-1.1,0-1.7,0c-0.4,0-0.6,0.2-0.6,0.6c0,0,0,0.1,0,0.2c-0.4-0.1-0.8-0.1-1.2-0.2c-1.1-0.3-2.2-0.7-3.1-1.4
                  c-0.4-0.3-0.8-0.7-1.1-1.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.3,0-0.4c0-0.4,0.3-0.6,0.7-0.6c0.8,0,1.7,0,2.5,0c0.1,0,0.1,0,0.2,0
                  c0-0.1,0-0.1,0-0.2c0-0.5,0-0.9,0-1.4c0-0.7,0.6-1.3,1.3-1.3c1.5,0,2.9,0,4.4,0c0.5,0,1,0.2,1.3,0.7c0.1,0.2,0.2,0.4,0.2,0.7
                  C21.5,24.3,21.5,24.8,21.5,25.3z M19.9,25.3c0-0.4,0-0.8,0-1.2c-1.3,0-2.5,0-3.8,0c0,0.4,0,0.8,0,1.2
                  C17.4,25.3,18.6,25.3,19.9,25.3z"/>
                <path class="st0" d="M11.1,28.6c1.6,1.4,3.4,2,5.4,2.3c0,0.1,0,0.2,0,0.3c0,0.3,0.3,0.6,0.6,0.6c0.6,0,1.2,0,1.7,0
                  c0.3,0,0.6-0.3,0.6-0.6c0-0.3,0-0.3,0.3-0.3c0.6-0.1,1.3-0.2,1.9-0.4c1.2-0.4,2.2-0.9,3.1-1.7c0,0,0.1,0,0.1-0.1c0,0.1,0,0.2,0,0.3
                  c0,1.8,0,3.5,0,5.3c0,0.5-0.2,0.7-0.7,0.7c-2.3,0-4.6,0-6.9,0c-1.8,0-3.7,0-5.5,0c-0.5,0-0.7-0.2-0.7-0.7c0-0.8,0-1.5,0-2.3
                  c0-1.1,0-2.1,0-3.2C11.1,28.7,11.1,28.7,11.1,28.6z"/>
              </g>
            </svg>
            <span class="category-header__title">Vacantes Disponibles</span>
            <svg class="icon icon--down-arrow category-header__accordion-icon hide-desk icon--svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.656 49.656">
              <path d="M1.414 14.535l2.828-2.828 20.586 20.585 20.586-20.585 2.828 2.828L24.828 37.95z"></path>
              <path d="M24.828 39.364L0 14.536l4.242-4.242 20.586 20.585 20.586-20.586 4.242 4.242-24.828 24.829zm-22-24.828l22 22 22-22.001-1.414-1.414-20.586 20.586L4.242 13.122l-1.414 1.414z"></path>
            </svg>
        </div>
        <div class="category-content category-content--first is-active" data-tab="390">
            <picture class="category-content__picture">
              <source media="(max-width: 390px)" srcset="/imagenes/vacantes.jpg">
              <source media="(max-width: 479px)" srcset="/imagenes/vacantes.jpg">
              <source media="(max-width: 767px)" srcset="/imagenes/vacantes.jpg">
              <source media="(max-width: 1023px)" srcset="/imagenes/vacantes.jpg">
              <img alt="Truck on the road" class="category-content__image" src="/imagenes/vacantes.jpg">
           </picture>
                      <!-- <div class="category-content__text categories-text category-content__text--rounded-corners">
                        <h3 class="categories-text__section">Asesor Comercial</h3>
                        <div class="categories-text__section"> Importante y reconocida compañía dedicada a ofrecer los servicios de transporte de carga internacional y servicios adicionales con valor agregado, requiere para su equipo de trabajo; profesionales en comercio exterior, negocios internacionales o afines. Con experiencia de 2 años y preferiblemente manejar inglés intermedio.</div>

                        <div class="categories-text__section" 
                              style="background: white;
                                    height: 50px;
                                    border-radius: 15px;
                                    text-align: center;
                                    padding-top: 12px;
                                    width: 171px;
                                    margin-left: 30%;
                                    font-size: larger;">
                          <a href="/solicitante.html" class="categories-text__section lk lk-effect " tabindex="" data-analytics="productsOverviewCta" style="color: #0a58ca;" >
                          <span>Aplicar Aquí</span>
                          </a>                
                        </div>
                      </div> -->
          </div>
          </div>
                        <br>
                        <div class="categories-text__section" 
                              style="background: #2E318A;
                                    height: 50px;
                                    border-radius: 15px;
                                    text-align: center;
                                    padding-top: 12px;
                                    width: 171px;
                                    margin-left: 55%;
                                    font-size: larger;">
                          <a href="/solicitante.html" class="categories-text__section lk lk-effect " tabindex="" data-analytics="productsOverviewCta" style="color: #0a58ca;" >
                          <span style="color: white;">Aplicar Aquí</span>
                          </a>                
                        </div>

          </section>
</template>

<script>
export default {

}
</script>

<style>

</style>