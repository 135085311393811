<template>
<SliderJobs />
</template>


<script>
import SliderJobs from '@/components/SliderJobs.vue'

export default {
  name: 'Job',
  
  components: {
    SliderJobs,
  },

  data: () => ({
    //
  }),

};
</script>